<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 py-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-1" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Filtros comunes -->
                  <!-- Fecha vigencia -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu-fecha-vigencia"
                      v-model="menuFechaVigencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVigenciaSelected"
                          label="Fecha de vigencia"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @blur="
                            fechaVigencia = parseDateToIso(
                              fechaVigenciaSelected
                            )
                          "
                          v-on="on"
                          :rules="fechaVigencia != null ? rules.validDate : []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVigencia"
                        no-title
                        @change="
                          fechaVigenciaSelected = formatDate(fechaVigencia)
                        "
                        @input="menuFechaVigencia = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tiposProcesos"
            item-key="bcpTipoId"
            class="elevation-1"
            show-expand
            :expanded.sync="expanded"
            :search="search"
            :loading="isLoading"
          >
            <template v-slot:[`item.utilizado`]="{ item }">
              <v-icon v-if="item.utilizado" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      class="to-right"
                      @click="openModal()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canCreate">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalCopyConfig(item)"
                  >
                    {{ copyIcon }}
                  </v-icon>
                </template>
                <span>Copiar configuración</span>
              </v-tooltip>
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    @click="openModal(item)"
                    v-on="on"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar tipo de proceso</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteProceso(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>{{
                  item.utilizado
                    ? "Proceso utilizado. No se puede eliminar"
                    : "Eliminar proceso"
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" md="3">
                    <strong>Fecha alta:</strong>
                    {{ item.fechaAlta }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Usuario alta:</strong>
                    {{ item.usuAlta }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Fecha modificación:</strong>
                    {{ item.fechaModi }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Usuario modificación:</strong>
                    {{ item.usuModi }}
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="60%"
      persistent
      @keydown.esc="closeModalEdit"
    >
      <EditProcesoMovMasivo
        :configProceso="configProceso"
        :toCopy="toCopy"
        @closeAndReload="closeModalEdit"
      ></EditProcesoMovMasivo>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'25%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditProcesoMovMasivo from "@/components/modules/afiliaciones/afiliados/EditProcesoMovMasivo.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConfigMovMasivosPadrones",
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    GoBackBtn,
    DeleteDialog,
    EditProcesoMovMasivo
  },
  directives: { mask },
  data: vm => ({
    menuFechaVigencia: null,
    fechaVigencia: new Date().toISOString().substr(0, 10),
    fechaVigenciaSelected: vm.formatDate(
      new Date().toISOString().substr(0, 10)
    ),
    routeToGo: "ConsultaMovMasivoPadrones",
    showExpand: false,
    showHelp: false,
    optionCode: enums.webSiteOptions.CONFIGURAR_PROCESO_MOV_MASIVO,
    showIcon: true,
    checkIcon: enums.icons.CHECK_OUTLINE,
    titleDelete: "¿Eliminar proceso?",
    showDeleteModal: false,
    title: enums.titles.CONFIG_MOVIMIENTOS_MASIVOS,
    searchIcon: enums.icons.SEARCH,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    copyIcon: enums.icons.COPY,
    rules: rules,
    isLoading: false,
    search: "",
    headers: [
      {
        text: "Nombre proceso",
        value: "bcpTipoNombre",
        sortable: false
      },
      {
        text: "Tipo de movimiento",
        value: "tipoMovimiento",
        sortable: false
      },
      {
        text: "Estado",
        value: "estadoNom",
        sortable: false
      },
      {
        text: "Vigencia desde",
        value: "vigenciaDesde",
        sortable: false
      },
      {
        text: "Vigencia hasta",
        value: "vigenciaHasta",
        sortable: false
      },
      {
        text: "Utilizado",
        value: "utilizado",
        align: "center",
        sortable: false
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end",
        width: "10%"
      },
      { text: "", align: "end", value: "data-table-expand", width: "1%" }
    ],
    tiposProcesos: [],
    showFilters: true,
    filtersApplied: [],
    expanded: [],
    isFormValid: true,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    openModalEdit: false,
    configProceso: null,
    toCopy: false,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    optionCode: "CCDF987B-FE28-4A26-8DFC-C8CA07ABBEB1"
  }),
  created() {
    this.applyFilters();
  },
  mounted() {
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      fetchConfigMovMasivosByParams:
        "afiliaciones/fetchConfigMovMasivosByParams",
      deleteProcesoConfigMovMasivos:
        "afiliaciones/deleteProcesoConfigMovMasivos",
      getPermissionsConfigMovMasivos:
        "afiliaciones/getPermissionsConfigMovMasivos",
      setAlert: "user/setAlert"
    }),
    async setPermisos() {
      const permisos = await this.getPermissionsConfigMovMasivos({
        optionCode: this.optionCode
      });
      this.canCreate = permisos.canCreate;
      this.canEdit = permisos.canEdit;
      this.canDelete = permisos.canDelete;
    },
    async applyFilters() {
      this.isLoading = true;
      this.customizeFiltersApplied();
      const filters = {
        fechaDesde: this.fechaVigencia
      };
      try {
        const response = await this.fetchConfigMovMasivosByParams(filters);
        this.tiposProcesos = response;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.fechaVigenciaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaVigencia",
          label: "Fecha de vigencia",
          model: this.fechaVigenciaSelected
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    openModalCopyConfig(item) {
      this.configProceso = item;
      this.toCopy = true;
      this.openModalEdit = true;
    },
    openModal(item) {
      this.configProceso = item;
      this.toCopy = false;
      this.openModalEdit = true;
    },
    closeModalEdit() {
      this.openModalEdit = false;
      this.applyFilters();
    },
    deleteProceso(item) {
      if (item.utilizado) return;
      this.showDeleteModal = true;
      this.idToDelete = item.bcpTipoId;
    },
    async confirmDelete() {
      const response = await this.deleteProcesoConfigMovMasivos(
        this.idToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({
          type: "success",
          message: response.data.data.mensajeDelete
        });
        this.applyFilters();
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
