<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="10" md="10" sm="8" class="py-0">
            <v-select
              v-model="variableSelected"
              :items="variables"
              item-text="variableNom"
              clearable
              item-value="variableCodigo"
              outlined
              dense
              label="Variable"
            >
              <template slot="item" slot-scope="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.variableNom }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Código: {{ item.variableCodigo }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2" md="2" sm="2" class="pt-0">
            <v-btn
              color="primary"
              :disabled="variableSelected == null"
              @click="insertVariableToFormula()"
            >
              Agregar
            </v-btn>
          </v-col>
          <v-col cols="10" md="10" sm="8" class="py-0">
            <v-select
              v-model="operacionSelected"
              :items="operaciones"
              item-text="descripcion"
              item-value="simbolo"
              outlined
              clearable
              dense
              label="Operación"
            >
              <template slot="item" slot-scope="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.descripcion }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Símbolo: {{ item.simbolo }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2" md="2" sm="2" class="pt-0">
            <v-btn
              color="primary"
              :disabled="operacionSelected == null"
              @click="insertOperadorToFormula()"
            >
              Agregar
            </v-btn>
          </v-col>
          <v-col cols="8" md="8" sm="8" class="py-0">
            <v-textarea
              v-model.trim="formula"
              auto-grow
              dense
              readonly
              disabled
              class="theme--light.v-input input"
              filled
              outlined
              label="Fórmula"
              rows="1"
            ></v-textarea>
          </v-col>
          <v-col cols="4" md="4" sm="4" class="py-0">
            <v-btn color="primary" @click="deleteLastValue()">
              Borrar último valor
            </v-btn>
          </v-col>
          <v-col cols="3" md="3" sm="4" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              label="Valor"
              v-model="valor"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="2" sm="6" class="pt-0">
            <v-btn
              color="primary"
              @click="insertValorToFormula()"
              :disabled="valor == null || valor == ''"
            >
              <v-icon left dark> {{ addIcon }} </v-icon>
              Agregar
            </v-btn>
          </v-col>
          <v-col cols="3" md="3" sm="12" class="pt-0">
            <v-btn color="primary" outlined @click="deleteFormula()">
              <v-icon left dark :color="'red'"> {{ deleteIcon }} </v-icon>
              Borrar fórmula
            </v-btn>
          </v-col>
          <v-col cols="4" md="4" sm="12" class="pt-0 pl-5">
            <v-btn
              color="primary"
              :disabled="formula == ''"
              :loading="loadingValidateFormula"
              outlined
              @click="validarFormula()"
            >
              <v-icon left dark :color="'green'"> {{ checkIcon }} </v-icon>
              Validar fórmula
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <p class="invalidConfigText pl-2" v-if="!validatedFormula">
          Se debe validar la fórmula para continuar.
        </p>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!validatedFormula"
          @click="saveFormula()"
          form="form-formula"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
    <DeleteDialog
      :maxWidth="'25%'"
      :titleProp="titleDelete"
      :confirmButtonText="'Borrar'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDeleteFormula()"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditFormulaMovMasivo",
  components: { DeleteDialog },
  props: {
    formulaToEdit: {
      Type: String,
      required: false
    }
  },
  data: () => ({
    formEditTitle: `Fórmula del proceso`,
    variables: [],
    operaciones: [],
    variableSelected: null,
    operacionSelected: null,
    formula: "",
    formulaValidada: "",
    valor: null,
    rules: rules,
    titleDelete: "¿Borrar fórmula?",
    showDeleteModal: false,
    validatedFormula: false,
    loadingValidateFormula: false,
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK,
    addIcon: enums.icons.CIRCLE_ADD_ITEM
  }),
  created() {
    this.setSelects();
    if (this.formulaToEdit != null) this.formula = this.formulaToEdit;
  },
  watch: {
    formula() {
      if (this.formula != this.formulaValidada) this.validatedFormula = false;
    }
  },
  methods: {
    ...mapActions({
      getVariablesYOperacionesMovMasivos:
        "afiliaciones/getVariablesYOperacionesMovMasivos",
      validarFormulaConfigMovMasivo:
        "afiliaciones/validarFormulaConfigMovMasivo",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const variablesYOperaciones = await this.getVariablesYOperacionesMovMasivos();
      this.variables = variablesYOperaciones.variables;
      this.operaciones = variablesYOperaciones.operaciones;
    },
    insertVariableToFormula() {
      if (this.formula != "") this.formula += " " + this.variableSelected;
      else this.formula += this.variableSelected;
      this.setAlert({ type: "success", message: "Variable agregada." });
      setTimeout(() => {
        this.variableSelected = null;
      }, 300);
    },
    insertOperadorToFormula() {
      if (this.formula != "") this.formula += " " + this.operacionSelected;
      else this.formula += this.operacionSelected;
      this.setAlert({ type: "success", message: "Operador agregado." });
      setTimeout(() => {
        this.operacionSelected = null;
      }, 300);
    },
    insertValorToFormula() {
      if (this.formula != "") this.formula += " " + this.valor;
      else this.formula += this.valor;
      this.setAlert({ type: "success", message: "Valor agregado." });
      setTimeout(() => {
        this.valor = null;
      }, 300);
    },
    async validarFormula() {
      this.loadingValidateFormula = true;
      const data = {
        formulaString: this.formula
      };
      const validateFormula = await this.validarFormulaConfigMovMasivo(data);
      if (validateFormula.tieneError) {
        this.setAlert({
          type: "error",
          message: validateFormula.mensajeError
        });
      } else {
        this.setAlert({ type: "success", message: "Fórmula correcta" });
        this.validatedFormula = true;
      }
      this.formulaValidada = this.formula;
      this.loadingValidateFormula = false;
    },
    saveFormula() {
      this.$emit("setFormula", this.formula);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    deleteFormula() {
      this.showDeleteModal = true;
    },
    confirmDeleteFormula() {
      this.formula = "";
      this.validatedFormula = false;
    },
    deleteLastValue() {
      let formulaData = this.formula.split(" ");
      formulaData.pop();
      this.formula = formulaData.join(' ');
    }
  }
};
</script>

<style scoped>
.no-upper-case {
  text-transform: none;
}
::v-deep .theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
.invalidConfigText {
  color: red;
}
</style>
