<template>
  <v-card :loading="loadingSetProceso">
    <v-container>
      <v-card-title>
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          :disabled="seeConfig"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <!-- Datos del proceso -->
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <h6>Datos del proceso</h6>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" md="6" sm="6" class="py-0">
              <v-textarea
                v-model.trim="procesoMovNom"
                auto-grow
                dense
                :rules="
                  rules.required.concat([
                    rules.maxLength(procesoMovNom, 100),
                    rules.requiredTrim(procesoMovNom)
                  ])
                "
                outlined
                label="Nombre del proceso"
                rows="1"
              ></v-textarea>
            </v-col>
            <!-- Tipo de movimiento -->
            <v-col cols="4" md="6" sm="6" class="py-0">
              <v-select
                v-model="tipoMovSelected"
                :items="tiposMovimiento"
                :disabled="configProceso != null && toCopy === false"
                :rules="rules.required"
                item-text="value"
                :loading="loadingSetProceso"
                @change="setEstadoComboByTipoMov()"
                item-value="id"
                label="Tipo de movimiento"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <!-- Vigencia desde -->
            <v-col cols="6" md="6" sm="6" class="py-0">
              <v-menu
                ref="vigencia-desde"
                v-model="menuVigenciaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="vigenciaDesdeSelected"
                    label="Vigencia desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="
                      vigenciaDesde = parseDateToIso(vigenciaDesdeSelected)
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        new Date(parseDateToIso(vigenciaDesdeSelected)) <=
                          new Date(parseDateToIso(vigenciaHastaSelected))
                      ) || 'Formato incorrecto'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="vigenciaDesde"
                  no-title
                  @change="vigenciaDesdeSelected = formatDate(vigenciaDesde)"
                  @input="menuVigenciaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Vigencia hasta -->
            <v-col cols="6" md="6" sm="6" class="py-0">
              <v-menu
                ref="vigencia-hasta"
                v-model="menuVigenciaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="vigenciaHastaSelected"
                    label="Vigencia hasta"
                    :append-icon="calendarIcon"
                    hint="Formato DD/MM/AAAA"
                    @blur="
                      vigenciaHasta = parseDateToIso(vigenciaHastaSelected)
                    "
                    outlined
                    autocomplete="not"
                    dense
                    v-mask="'##/##/####'"
                    v-bind="attrs"
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        new Date(parseDateToIso(vigenciaDesdeSelected)) <=
                          new Date(parseDateToIso(vigenciaHastaSelected))
                      ) || 'Formato incorrecto'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="vigenciaHasta"
                  no-title
                  @change="vigenciaHastaSelected = formatDate(vigenciaHasta)"
                  @input="menuVigenciaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="tipoMovSelected === 'B'"
              cols="6"
              md="6"
              sm="6"
              class="py-0 my-n5 pb-4"
            >
              <v-switch
                v-model="desdeUnArchivo"
                label="Desde un archivo"
              ></v-switch>
            </v-col>
          </v-row>
          <!-- Filtros para afiliados -->
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <h6>Filtros</h6>
            </v-col>
          </v-row>
          <v-row>
            <!-- Estado -->
            <v-col v-if="!desdeUnArchivo" cols="6" md="6" sm="12" class="py-0">
              <v-select
                v-model="estadoSelected"
                :items="estados"
                :rules="rules.required"
                :loading="loadingSetProceso"
                ref="estadoBenefProceso"
                item-text="value"
                @change="setNuevoEstadoCombo()"
                :disabled="estadoDisabled"
                item-value="id"
                label="Estado"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
            <!-- Incluir grupo familiar -->
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-switch
                v-model="incluirGrpFamiliar"
                id="switch1"
                class="my-0"
                label="Incluir al grupo familiar"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <!-- Convenios -->
            <v-col v-if="!desdeUnArchivo" cols="6" md="6" sm="6" class="py-0">
              <v-select
                outlined
                clearable
                dense
                item-text="value"
                :rules="[conveniosSelected.length != 0]"
                item-value="id"
                multiple
                :loading="loadingSetProceso"
                return-object
                @change="setPlanesByConvenios()"
                v-model="conveniosSelected"
                label="Convenios"
                :items="convenios"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="conveniosToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="conveniosSelected.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIconConvenios }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ conveniosSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-select>
            </v-col>
            <!-- Planes -->
            <v-col v-if="!desdeUnArchivo" cols="6" md="6" sm="6" class="py-0">
              <v-select
                outlined
                clearable
                dense
                item-text="value"
                item-value="id"
                multiple
                return-object
                :loading="loadingPlanesByConvenio"
                v-model="planesSelected"
                label="Planes"
                :items="planes"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="planesToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="planesSelected.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIconPlanes }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ planesSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- Formula -->
          <v-row>
            <v-col v-if="!desdeUnArchivo" cols="9" md="9" sm="6" class="py-0">
              <v-textarea
                v-model="formula"
                auto-grow
                disabled
                class="theme--light.v-input input"
                filled
                dense
                outlined
                label="Fórmula"
                rows="1"
              ></v-textarea>
            </v-col>
            <v-col v-if="!desdeUnArchivo" cols="3" md="3" sm="6" class="pt-0">
              <v-btn
                color="primary"
                @click="toggleModalEditFormula()"
                :disabled="seeConfig"
              >
                Editar fórmula
              </v-btn>
            </v-col>
          </v-row>
          <!-- Datos para el nuevo movimiento -->
          <v-row
            v-if="
              tipoMovSelected != null &&
                tipoMovSelected != 'R' &&
                !desdeUnArchivo
            "
          >
            <v-col cols="12" md="12" sm="12" class="py-0">
              <h6>Datos para el nuevo movimiento</h6>
            </v-col>
          </v-row>
          <!-- Nuevo estado de baja -->
          <v-row v-if="tipoMovSelected === 'B' && !desdeUnArchivo">
            <v-col cols="6" md="6" sm="6" class="py-0">
              <v-select
                v-model="nuevoEstadoSelected"
                :items="nuevosEstados"
                :loading="loadingSetProceso"
                item-text="value"
                :rules="rules.required"
                item-value="id"
                label="Nuevo estado"
                @change="setTipoMovBajaByNuevoEstado()"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
            <!-- Tipo de baja -->
            <v-col cols="6" md="6" sm="6" class="py-0" v-show="false">
              <v-select
                v-model="tipoMovBajaSelected"
                :items="tiposMovBaja"
                item-text="value"
                :rules="rules.required"
                :loading="loadingSetProceso"
                item-value="id"
                @change="setMotivosBajaByTipoBaja(tipoMovBajaSelected)"
                label="Tipo de baja"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
            <!-- Motivo de baja -->
            <v-col cols="6" md="6" sm="6" class="py-0">
              <v-select
                v-model="motivoDeBajaSelected"
                :items="motivosDeBaja"
                :rules="rules.required"
                :loading="loadingSetProceso"
                item-text="value"
                item-value="id"
                label="Motivo de baja"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Motivo de cambio de convenio y plan -->
          <v-row v-if="tipoMovSelected === 'C'">
            <v-col cols="5" md="5" sm="6" class="py-0">
              <v-select
                v-model="motivoCambioDePlanSelected"
                :items="motivosCambioDePlan"
                :loading="loadingSetProceso"
                :rules="rules.required"
                item-text="value"
                item-value="id"
                label="Motivo de cambio de plan"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Convenios origenes y destino -->
          <v-row v-if="tipoMovSelected === 'C'">
            <v-col cols="5" md="5" sm="6" class="py-0">
              <v-select
                v-model="convOrigenSelected"
                :items="conveniosSelected"
                item-text="value"
                item-value="id"
                return-object
                @change="setPlanesByConvenioOrigen"
                label="Convenio origen"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="5" md="5" sm="6" class="py-0">
              <v-select
                v-model="convDestinoSelected"
                :items="convenios"
                return-object
                item-text="value"
                item-value="id"
                label="Convenio destino"
                @change="setPlanesByConvenioDestino"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Planes origenes y destino -->
          <v-row v-if="tipoMovSelected === 'C'">
            <v-col cols="5" md="5" sm="4" class="py-0">
              <v-select
                v-model="planOrigenSelected"
                :items="
                  planesSelected.length > 0
                    ? planesSelected
                    : planesByConvenioOrigen
                "
                item-text="value"
                return-object
                item-value="id"
                label="Plan origen"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="5" md="5" sm="4" class="py-0">
              <v-select
                v-model="planDestinoSelected"
                :items="planesByConvenioDestino"
                item-text="value"
                return-object
                item-value="id"
                label="Plan destino"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="2" md="2" sm="2" class="pt-0">
              <v-btn color="primary" @click="addRelationshipConvPlan()">
                Añadir
              </v-btn>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="cambioConvenioRelations"
                  class="elevation-1"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="seeConfig === false"
                          v-bind="attrs"
                          size="20"
                          v-on="on"
                          @click="deleteRelationshipItem(item.bcpTipoCPId)"
                        >
                          {{ deleteIcon }}
                        </v-icon>
                      </template>
                      <span>Quitar relación</span>
                    </v-tooltip>
                  </template>
                  <!-- ToDo cambiar por una mejor práctica(https://codepen.io/sunhao1256/pen/MWeZyMe) -->
                  <template v-slot:[`header`]="">
                    <thead>
                      <tr>
                        <th
                          colspan="1"
                          class="fontColor"
                          style="text-align: center"
                        >
                          Datos origen
                        </th>
                        <th colspan="" style="text-align: right">|</th>
                        <th
                          colspan="2"
                          class="fontColor"
                          style="text-align: center"
                        >
                          Datos destino
                        </th>
                        <th colspan="" style="text-align: left"></th>
                      </tr>
                    </thead>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          v-if="seeConfig === false"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
        <!-- </div> -->
      </v-card-actions>
    </v-container>
    <v-dialog
      v-if="modalEditFormula"
      v-model="modalEditFormula"
      max-width="60%"
      persistent
      @keydown.esc="toggleModalEditFormula"
    >
      <EditFormulaMovMasivo
        :formulaToEdit="formula"
        @setFormula="setFormula($event)"
        @closeModal="toggleModalEditFormula"
      ></EditFormulaMovMasivo>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :textDelete="textDelete"
      :maxWidth="'25%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import EditFormulaMovMasivo from "@/components/modules/afiliaciones/afiliados/EditFormulaMovMasivo.vue";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  name: "EditProcesoMovMasivo",
  props: {
    configProceso: {
      type: Object,
      required: false
    },
    toCopy: {
      type: Boolean,
      required: false
    },
    seeConfig: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { EditFormulaMovMasivo, DeleteDialog },
  directives: { mask },
  data: () => ({
    deleteIcon: enums.icons.DELETE,
    calendarIcon: enums.icons.CALENDAR,
    isFormValid: false,
    formEditTitle: "",
    titleDelete: "¿Eliminar relación?",
    showDeleteModal: false,
    rules: rules,
    procesoMovNom: null,
    loadingSetProceso: false,
    loadingPlanesByConvenio: false,
    cambioConvenioRelations: [],
    headers: [
      {
        text: "Convenio origen",
        value: "osIdOrigenNom",
        sortable: false,
        align: "start"
      },
      {
        text: "Plan origen",
        value: "planIdOrigenNom",
        sortable: false
      },
      {
        text: "Convenio destino",
        value: "osIdDestinoNom",
        sortable: false,
        align: "start"
      },
      {
        text: "Plan destino",
        value: "planIdDestinoNom",
        sortable: false
      },
      { text: "", value: "actions", sortable: false, align: "end" }
    ],
    tiposMovimiento: [
      {
        id: "B",
        value: "Baja"
      },
      { id: "R", value: "Reincorporación" },
      { id: "C", value: "Cambio de convenio y/o plan" }
    ],
    tipoMovSelected: null,
    estados: [],
    allEstados: [],
    estadoSelected: null,
    conveniosSelected: [],
    convenios: [],
    planesSelected: [],
    planes: [],
    formula: null,
    estadoDisabled: false,
    tiposMovBaja: [],
    tipoMovBajaSelected: null,
    motivoDeBajaSelected: null,
    motivosDeBaja: [],
    motivoCambioDePlanSelected: null,
    motivosCambioDePlan: [],
    convOrigenSelected: null,
    convDestinoSelected: null,
    planOrigenSelected: null,
    planesByConvenioOrigen: [],
    planDestinoSelected: null,
    planesByConvenioDestino: [],
    modalEditFormula: false,
    menuVigenciaDesde: false,
    menuVigenciaHasta: false,
    vigenciaDesdeSelected: null,
    vigenciaHastaSelected: null,
    vigenciaDesde: null,
    vigenciaHasta: null,
    textDelete: "",
    openVerConfigTipoProceso: {},
    nuevoEstadoSelected: null,
    nuevosEstados: [],
    loadingSaveBtn: false,
    incluirGrpFamiliar: true,
    desdeUnArchivo: false
  }),
  created() {
    if (this.configProceso == null)
      this.formEditTitle = "Nuevo tipo de proceso";
    else if (this.seeConfig) {
      this.formEditTitle =
        "Ver tipo de proceso: " + this.configProceso.bcpTipoNombre;
      this.setProceso();
    } else if (this.toCopy) {
      this.formEditTitle =
        "Copiar tipo de proceso: " + this.configProceso.bcpTipoNombre;
      this.setProceso();
    } else {
      this.formEditTitle =
        "Editar tipo de proceso: " + this.configProceso.bcpTipoNombre;
      this.setProceso();
    }
    this.setSelects();
  },
  computed: {
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllConvenios() {
      return (
        this.conveniosSelected &&
        this.conveniosSelected.length === this.convenios.length
      );
    },
    multiselectIconPlanes() {
      if (this.selectAllPlanes) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllPlanes() {
      return (
        this.planesSelected && this.planesSelected.length === this.planes.length
      );
    }
  },
  methods: {
    ...mapActions({
      getEstadosBenef: "configAfiliaciones/getEstadosBenef",
      getConvenios: "afiliaciones/getConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      getTiposMovBaja: "configAfiliaciones/getTiposMovBaja",
      getMotivosBajaByTipoBaja: "configAfiliaciones/getMotivosBajaByTipoBaja",
      getCambiosConvenioPlanById: "afiliaciones/getCambiosConvenioPlanById",
      getMotivosTipoBajaMovMasivos: "afiliaciones/getMotivosTipoBajaMovMasivos",
      postConfigProcesoMovMasivos: "afiliaciones/postConfigProcesoMovMasivos",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const convenios = await this.getConvenios();
      this.convenios = convenios;
    },
    async setCambiosConvenioYPlan(id) {
      const response = await this.getCambiosConvenioPlanById(id);
      this.cambioConvenioRelations = response;
    },
    async setMotivosTipoBaja(id) {
      const response = await this.getMotivosTipoBajaMovMasivos(id);
      this.nuevoEstadoSelected = response.estId;
      this.setTipoMovBajaByNuevoEstado();
      this.tipoMovBajaSelected = response.tCambioId;
      this.setMotivosBajaByTipoBaja(response.tCambioId);
      this.motivoDeBajaSelected = response.tipoMovMotivoId;
    },
    async setProceso() {
      this.desdeUnArchivo = this.configProceso.desdeArchivo;
      this.loadingSetProceso = true;
      this.procesoMovNom = this.configProceso.bcpTipoNombre;
      const tipoMov = this.configProceso.tipoMovimiento.charAt(0);
      this.tipoMovSelected = tipoMov;
      await this.setEstadoComboByTipoMov();
      this.estadoSelected = this.configProceso.estadoId;
      this.setNuevoEstadoCombo();
      this.conveniosSelected = this.configProceso.getConvenios;
      await this.setPlanesByConvenios();
      this.planesSelected = this.configProceso.getPlanes;
      this.formula = this.configProceso.formula;
      this.incluirGrpFamiliar = this.configProceso.incluirGrpFamiliar;
      this.vigenciaDesdeSelected = this.configProceso.vigenciaDesde;
      this.vigenciaDesde = this.parseDateToIso(
        this.configProceso.vigenciaDesde
      );
      this.vigenciaHastaSelected = this.configProceso.vigenciaHasta;
      this.vigenciaHasta = this.parseDateToIso(
        this.configProceso.vigenciaHasta
      );
      if (tipoMov === "C") {
        this.motivoCambioDePlanSelected = this.configProceso.tipoMovMotivoIdCambioPlan;
        this.setCambiosConvenioYPlan(this.configProceso.bcpTipoId);
      } else if (tipoMov === "B") {
        // setTimeout(() => {
        //   this.setNuevoEstadoCombo();
        // }, 500);
        await this.setMotivosTipoBaja(this.configProceso.bcpTipoId);
      }
      if (this.toCopy) {
        this.procesoMovNom = "Copia - " + this.configProceso.bcpTipoNombre;
      }
      this.loadingSetProceso = false;
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        bcpTipoId:
          this.configProceso != null && !this.toCopy
            ? this.configProceso.bcpTipoId
            : 0,
        bcpTipoNombre: this.procesoMovNom,
        tipoMovimiento: this.tipoMovSelected,
        estadoId: this.desdeUnArchivo ? null : this.estadoSelected,
        vigenciaDesde: this.vigenciaDesde,
        vigenciaHasta: this.vigenciaHasta,
        formula: this.desdeUnArchivo ? null : this.formula,
        tipoBajaId:
          this.tipoMovSelected === "B" ? this.tipoMovBajaSelected : null,
        tipoBajaMotivoId:
          this.tipoMovSelected === "B" ? this.motivoDeBajaSelected : null,
        planes: this.desdeUnArchivo ? [] : this.planesSelected.map(x => x.id),
        convenios: this.desdeUnArchivo
          ? []
          : this.conveniosSelected.map(x => x.id),
        cambioOsPlanDTOs:
          this.tipoMovSelected === "C" ? this.cambioConvenioRelations : [],
        nuevoEstadoId:
          this.tipoMovSelected === "B" ? this.nuevoEstadoSelected : null,
        incluirGrpFamiliar: this.incluirGrpFamiliar,
        tipoMovMotivoIdCambioPlan:
          this.tipoMovSelected === "C" ? this.motivoCambioDePlanSelected : null,
        desdeArchivo: this.desdeUnArchivo
      };
      const response = await this.postConfigProcesoMovMasivos(data);
      if (response.status === 200) {
        this.setAlert({
          type: "success",
          message: "Guardado con éxito."
        });
        this.loadingSaveBtn = false;
        this.closeModal();
      }
    },
    planesToggle() {
      this.$nextTick(() => {
        if (this.selectAllPlanes) {
          this.planesSelected = [];
        } else {
          this.planesSelected = this.planes;
        }
      });
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) {
          this.conveniosSelected = [];
          this.planes = [];
          this.planesSelected = [];
        } else {
          this.conveniosSelected = this.convenios;
          this.setPlanesByConvenios();
        }
      });
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    async setPlanesByConvenios() {
      this.loadingPlanesByConvenio = true;
      const conveniosIds = this.conveniosSelected.map(x => x.id);
      const planes = await this.getPlanesByConvenio(conveniosIds);
      this.planes = planes;
      this.loadingPlanesByConvenio = false;
    },
    async setPlanesByConvenioDestino() {
      if (this.convDestinoSelected == null) {
        this.planesByConvenioDestino = [];
        this.planDestinoSelected = null;
        return;
      }
      let conveniosIds = [];
      conveniosIds.push(this.convDestinoSelected.id);
      const planes = await this.getPlanesByConvenio(conveniosIds);
      this.planesByConvenioDestino = planes;
    },
    async setPlanesByConvenioOrigen() {
      if (this.convOrigenSelected == null) {
        this.planesByConvenioOrigen = [];
        this.planOrigenSelected = null;
        return;
      }
      if (this.planesSelected.length === 0) {
        let conveniosIds = [];
        conveniosIds.push(this.convOrigenSelected.id);
        const planes = await this.getPlanesByConvenio(conveniosIds);
        this.planesByConvenioOrigen = planes;
      }
    },
    async setEstadoComboByTipoMov() {
      const estadosBenef = await this.getEstadosBenef();
      this.estados = estadosBenef;
      this.allEstados = estadosBenef;
      if (this.tipoMovSelected != "B") {
        this.desdeUnArchivo = false;
      }

      if (this.tipoMovSelected === "B") {
        const tiposMovBaja = await this.getTiposMovBaja();
        this.tiposMovBaja = tiposMovBaja;
        // this.$refs.estadoBenefProceso.reset();
        this.estadoSelected = null;
        this.estadoDisabled = false;
        this.estados = this.estados.filter(x => x.id !== 2);
      } else if (this.tipoMovSelected === "C") {
        // tipo de movimiento 6 = cambio de plan
        const motivosPlan = await this.getMotivosBajaByTipoBaja(6);
        this.motivosCambioDePlan = motivosPlan;
        this.estadoDisabled = true;
        this.estadoSelected = 1;
      } else if (this.tipoMovSelected == null) {
        this.estados = [];
        // this.$refs.estadoBenefProceso.reset();
        this.estadoDisabled = false;
        this.estadoSelected = null;
      } else {
        //reincorporacion -> remueve el estado 'con cobertura'
        this.estadoSelected = null;
        this.estados = this.estados.filter(x => x.id != 1);
        this.estadoDisabled = false;
      }
    },
    setNuevoEstadoCombo() {
      if (this.estadoSelected === 4) {
        this.nuevosEstados = this.allEstados.filter(x => x.id === 2);
      } else {
        this.nuevosEstados = this.allEstados.filter(
          x => x.id != this.estadoSelected && x.id !== 1
        );
      }
    },
    setTipoMovBajaByNuevoEstado() {
      if (this.nuevoEstadoSelected == null) {
        this.tipoMovBajaSelected = null;
        this.motivoDeBajaSelected = null;
        this.motivosDeBaja = [];
        return;
      } else {
        if (this.nuevoEstadoSelected === 3 || this.nuevoEstadoSelected === 4)
          this.tipoMovBajaSelected = 18;
        else this.tipoMovBajaSelected = 3;
        this.setMotivosBajaByTipoBaja(this.tipoMovBajaSelected);
      }
    },
    async setMotivosBajaByTipoBaja(id) {
      if (id == undefined && id == null) {
        this.motivosDeBaja = [];
        this.motivoDeBajaSelected = null;
      } else {
        const motivosBaja = await this.getMotivosBajaByTipoBaja(id);
        this.motivosDeBaja = motivosBaja;
      }
    },
    addRelationshipConvPlan() {
      let idToPush = 0;
      if (
        this.convOrigenSelected != null &&
        this.convDestinoSelected != null &&
        this.planOrigenSelected != null &&
        this.planDestinoSelected != null
      ) {
        let sameConfig = false;
        let sameConvAndPlan = false;
        this.cambioConvenioRelations.forEach(x => {
          if (
            x.osIdOrigen === this.convOrigenSelected.id &&
            x.osIdDestino === this.convDestinoSelected.id &&
            x.planIdOrigen === this.planOrigenSelected.id &&
            x.planIdDestino === this.planDestinoSelected.id
          )
            sameConfig = true;
          else if (
            x.osIdOrigen === this.convOrigenSelected.id &&
            x.planIdOrigen === this.planOrigenSelected.id
          )
            sameConvAndPlan = true;
        });
        if (sameConfig) {
          this.setAlert({
            type: "warning",
            message: "Ya existe un registro con los datos ingresados."
          });
          return;
        }
        if (sameConvAndPlan) {
          this.setAlert({
            type: "warning",
            message:
              "Ya existe un registro configurado para el mismo convenio y plan ingresado."
          });
          return;
        }

        if (this.cambioConvenioRelations.length !== 0)
          idToPush = this.cambioConvenioRelations.length;

        const dataToPush = {
          bcpTipoCPId: idToPush,
          osIdOrigen: this.convOrigenSelected.id,
          osIdOrigenNom: this.convOrigenSelected.value,
          osIdDestino: this.convDestinoSelected.id,
          osIdDestinoNom: this.convDestinoSelected.value,
          planIdOrigen: this.planOrigenSelected.id,
          planIdOrigenNom: this.planOrigenSelected.value,
          planIdDestino: this.planDestinoSelected.id,
          planIdDestinoNom: this.planDestinoSelected.value
        };
        this.cambioConvenioRelations.push(dataToPush);

        this.convOrigenSelected = null;
        this.planOrigenSelected = null;
        this.convDestinoSelected = null;
        this.planDestinoSelected = null;
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingresar todos los campos para agregar una relación."
        });
      }
    },
    setFormula(formula) {
      this.toggleModalEditFormula();
      this.formula = formula;
    },
    deleteRelationshipItem(id) {
      this.textDelete =
        "La eliminación de la relación impactará en el momento que se guarden los cambios.";
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    toggleModalEditFormula() {
      this.modalEditFormula = !this.modalEditFormula;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    async confirmDelete() {
      this.cambioConvenioRelations = this.cambioConvenioRelations.filter(
        x => x.bcpTipoCPId != this.idToDelete
      );
      this.setAlert({
        type: "success",
        message: "Eliminado con éxito."
      });
      this.showDeleteModal = false;
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.no-upper-case {
  text-transform: none;
}
::v-deep .theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>
